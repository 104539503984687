import { IconLocation } from '../../utils/URLUtils';
import styles from './HeaderNavigation.module.scss';
import { useEffect, useState } from 'react';
import MainNavigation from '../MainNavigation/MainNavigation';
import { useLocation } from 'react-router-dom';
import { ConvertURL } from './ConvertURL';

const HeaderNavigation = () => {
    const [showNavigation, setShowNavigation] = useState(false);
    const [title, setTitle] = useState('Lesoons');
    const location = useLocation();
    const toggleNavigation = () => {
        setShowNavigation(prevState => !prevState);
    };

    useEffect(() => {
        setTitle(ConvertURL(location.pathname));
    }, [location.pathname]);

    return (

        <div className={styles.headerNavigation}>
            <MainNavigation toggleNavigation={toggleNavigation} show={showNavigation} />
            <img className={styles.menu} src={IconLocation('menu')} alt="Menu" onClick={toggleNavigation} />
            <h1 className={styles.headerTitle}> {title} </h1>
        </div>

    );
}

export default HeaderNavigation;
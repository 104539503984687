export function ConvertURL(path: string) {
    switch (path) {
        case "/lessons":
            return 'Lesoons';
        case "/discover":
            return 'Discover';
        case "/learn":
            return 'Learn';
        case "/practice":
            return 'Practice';
        case "/progress":
            return 'Progress';
        default:
            return 'Lesoons';
    }
}


const BaseFileLocation = "https://cdn-wordup.com";
const IsDark = () => false;

export function ImageLocation(fileName: string) {
  return `${BaseFileLocation}/Hybrid/${fileName}`;
}

export function IconLocation(fileName: string, forceLight: boolean = false) {
  return `${BaseFileLocation}/Hybrid/${(IsDark() || forceLight ? 'white' : 'black')}/${fileName}.png`;
}


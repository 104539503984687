import { ImageLocation } from '../utils/URLUtils';
import styles from './Practice.module.scss';
import { PracticeItem } from './practiceItem/PracticeItem';

const practices = [
    {
        title: "Today's Reviews",
        description: `Words and phrases due for review today.`,
        imageUrl: '',
        pro: false,
        first: true,
    },
    {
        title: "Practice Speaking",
        description: `Talk about anything you like with Lexi, your AI tutor! Get instant feedback!`,
        imageUrl: ImageLocation('Lexi/Lexi-Speaking.png'),
        pro: false,
        first: false,
    },
    {
        title: "Fantasy Chat",
        description: `Celebrity AI roleplay! Practice with your favourite people!`,
        imageUrl: ImageLocation('fantasy.webp'),
        pro: false,
        first: false,
    },
    {
        title: "Smart Writing",
        description: `Improve your writing with instant tips and feedback from Lexi!`,
        imageUrl: ImageLocation('Lexi/Lexi-Writing.png'),
        pro: false,
        first: false,
    },
    {
        title: "Scenarios",
        description: `Focus on the words used in different real-life situations.`,
        imageUrl: 'https://cdn-wordup.com/scenarios/enpa.webp',
        pro: true,
        first: false,
    },
    {
        title: "AI Vocab Review",
        description: `Practice vocabulary with Lexi, your AI tutor!`,
        imageUrl: ImageLocation('Lexi/Lexi-General.png'),
        pro: false,
        first: false,
    },
]

export const Practice = () => {

    return (
        <article className={styles.practice}>
            {practices.map((practice, index) => (
                <PracticeItem key={index} practice={practice} />
            ))}
        </article>

    );

}


import { IconLocation, ImageLocation } from '../../utils/URLUtils';
import styles from './PracticeItem.module.scss';

interface Practice {
    title: string;
    description: string;
    imageUrl: string;
    pro: boolean;
    first: boolean;
}

export const PracticeItem = ({ practice }: { practice: Practice }) => {
    return (
        <div className={styles.practiceItem}>
            {!practice.first && <img src={practice.imageUrl} alt={practice.title} />}
            {practice.first &&
                <div className={styles.first}>
                    <span>8</span>
                </div>
            }
            <div className={styles.practiceItemContent}>
                <div className={styles.practiceItemContentText}>
                    <h2>{practice.title}</h2>
                    <p>{practice.description}</p>
                </div>
                {practice.first &&
                    <div className={styles.practiceItemContentButton}>
                        <img src={IconLocation('chevron-right')} alt="Button" />
                    </div>
                }
                {practice.pro && <img className={styles.pro} src={ImageLocation('Pro.png')} alt="Pro" />}
            </div>
        </div>
    );
}
import React, { useState } from 'react';
import styles from './MainNavigation.module.scss';
import { IconLocation, ImageLocation } from '../../utils/URLUtils';

const MainNavigation: React.FC<{ toggleNavigation: () => void, show: boolean }> = ({ toggleNavigation, show }) => {
    const [theme, setTheme] = useState(false);
    const [pronounce, setPronounce] = useState(false);

    return (
        <>
            {show && <div className={styles.overlay} onClick={toggleNavigation} />}
            <nav className={`${styles.mainNavigation} ${show ? styles.visible : ''}`}>
                <img className={styles.logo} src={ImageLocation('logo.webp')} alt="Logo" />
                <button className={styles.proButton}>Get WordUp Pro</button>
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('search')} alt="Close" />
                        <span className={styles.text}>Search</span>
                    </div>
                </div>
                <hr className={styles.thick} />
                <div className={styles.section}>
                    <div className={styles.row} >
                        <img className={styles.icon} src={IconLocation('tablet')} alt="Close" />
                        <span className={styles.text}>Theme</span>
                    </div>
                    <div className={styles.switch}>
                        <div className={theme ? styles.active : styles.notActive}
                            onClick={() => setTheme(true)}><img src={IconLocation('moon')} alt="Mooon" /></div>
                        <div className={!theme ? styles.active : styles.notActive} onClick={() => setTheme(false)}><img src={IconLocation('sun')} alt="Sun" /></div>
                    </div>
                </div>
                <hr className={styles.thin} />
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('volume-1')} alt="Close" />
                        <span className={styles.text}>Pronounce</span>
                    </div>
                    <div className={styles.switch}>
                        <div className={pronounce ? styles.activeGray : styles.notActive}
                            onClick={() => setPronounce(true)}>
                            <span>Off</span>
                        </div>
                        <div className={!pronounce ? styles.active : styles.notActive}
                            onClick={() => setPronounce(false)}>
                            <span>On</span>
                        </div>
                    </div>
                </div>
                <hr className={styles.thick} />
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('settings')} alt="Close" />
                        <span className={styles.text}>More Settings</span>
                    </div>
                    <img className={styles.arrow} src={IconLocation('chevron-right')} alt="Arrow" />
                </div>
                <hr className={styles.thick} />
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('quote')} alt="Close" />
                        <span className={styles.text}>My Interests</span>
                    </div>
                    <img className={styles.arrow} src={IconLocation('chevron-right')} alt="Arrow" />
                </div>
                <hr className={styles.thin} />
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('chrome-extension')} alt="Close" />
                        <span className={styles.text}>Chrome Extension</span>
                    </div>
                </div>
                <hr className={styles.thin} />
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('repeat')} alt="Close" />
                        <span className={styles.text}>Sync devices</span>
                    </div>
                </div>
                <hr className={styles.thin} />
                <div className={styles.section}>
                    <div className={styles.row}>
                        <img className={styles.icon} src={IconLocation('tool')} alt="Close" />
                        <span className={styles.text}>Support & feedback</span>
                    </div>
                    <img className={styles.arrow} src={IconLocation('chevron-right')} alt="Arrow" />
                </div>
                <hr className={styles.thin} />
                <div className={styles.section}>
                    <button className={styles.buttonGroups}>About us</button>
                    <button className={styles.buttonGroups}>Privacy</button>
                    <button className={styles.buttonGroups}>Share</button>
                </div>
                <div className={styles.section}>
                    <div className={styles.video}>
                        <img src={IconLocation('play-circle')} alt="Video" />
                        <span>Learn how to use WordUp</span>
                    </div>
                </div>
                <p className={styles.version}>
                    Version 6.5.10
                </p>
            </nav >
        </>
    );
};

export default MainNavigation;
import { Component } from 'react';
import { HomePage } from './components/HomePage';
import Layout from './components/layout/Layout';
class App extends Component {


  render() {
    return (
      <>
        <Layout>
          <HomePage />
        </Layout>

      </>
    );
  }
}

export default App;

import { Routes, Route } from 'react-router-dom';
import { Practice } from './practice/Practice';


const HomePage = () => {
    return (
        <Routes >
            <Route path='/practice' element={<Practice />}></Route>
        </Routes >
    );
}

export { HomePage };

import FooterNavigation from './FooterNavigation/FooterNavigation'
import React, { ReactNode } from 'react';
import styles from './Layout.module.scss';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';


const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <div className={styles.layout}>
            <header className={styles.header}>
                <HeaderNavigation />
            </header>

            <main className={styles.main}>
                {children}
            </main>

            <footer className={styles.footer}>
                <FooterNavigation />
            </footer>
        </div>
    )
}

export default Layout